import { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import LoadingOverlay from "components/Common/LoadingOverlay";
import { getId } from "components/EntityWrappers/episodeWrapper";
import Error404 from "pages/Error404/Async";

import EpisodeView from "./EpisodeView";

import podcastActions from "actions/podcast";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useEpisodeData, {
  useEpisodeDataIsLoading,
  useEpisodeDataFailed,
} from "hooks/useEpisodeData";
import useExecOnMount from "hooks/useExecOnMount";
import usePodcastCategories from "hooks/usePodcastCategories";
import usePodcastData, {
  usePodcastDataIsLoading,
  usePodcastDataFailed,
} from "hooks/usePodcastData";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "318rem",
    maxHeight: "318rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "200rem",
      maxHeight: "200rem",
    },
  },
};

const compareFunc = (compProps, oldCompProps) =>
  compProps.podcastID !== oldCompProps.podcastID;

const EpisodeViewContainer = () => {
  const location = useLocation();
  const params = useParams();

  const episodeId = getId({ params, location });

  const episode = useEpisodeData(episodeId);

  const podcastID = episode?.get("podcast_id");

  const podcast = usePodcastData(podcastID);

  const episodeLoading = useEpisodeDataIsLoading(episodeId);
  const podcastLoading = usePodcastDataIsLoading(podcastID);

  const isEpisodeFailed = useEpisodeDataFailed(episodeId);
  const isPodcastFailed = usePodcastDataFailed(podcastID);

  const categories = usePodcastCategories(podcastID);

  const { loadSpecificPodcast, loadSpecificEpisode } = useActionCreators({
    loadSpecificPodcast: podcastActions.loadSpecificPodcast,
    loadSpecificEpisode: podcastActions.loadSpecificEpisode,
  });

  const loadEpisodeFlag = episodeId && !episodeLoading && !episode;

  const hasFullyPodcastRecord = podcast && podcast.get("full_record");
  const loadPodcastFlag =
    podcastID && !podcastLoading && !hasFullyPodcastRecord;

  const loadEpisode = useCallback(() => {
    if (loadEpisodeFlag) {
      loadSpecificEpisode(episodeId);
    }
  }, [loadEpisodeFlag, loadSpecificEpisode, episodeId]);

  useExecOnMount(loadEpisode, loadEpisodeFlag, { episodeId }, compareFunc);

  const loadPodcast = useCallback(() => {
    if (loadPodcastFlag) {
      loadSpecificPodcast(podcastID);
    }
  }, [loadPodcastFlag, loadSpecificPodcast, podcastID]);

  useExecOnMount(loadPodcast, loadPodcastFlag, { podcastID }, compareFunc);

  useEffect(() => {
    if (podcastID && episode && podcast) {
      sendGAEvent({
        action: "episodePageImpression",
        podcast_id: podcastID,
        episode_id: episode.get("id"),
        page: "episode",
        pageType: "EpisodeView",
        page_id: episode.get("id"),
        page_name: episode.get("title"),
        path: location?.pathname,
        podcast_name: podcast.get("title"),
        episode_name: episode.get("title"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastID]);

  if (!podcast && isPodcastFailed) {
    return <Error404 />;
  }

  if (!episode && isEpisodeFailed) {
    return <Error404 />;
  }

  if (podcast && episode) {
    return (
      <EpisodeView
        podcast={podcast}
        episode={episode}
        categories={categories}
      />
    );
  }

  return <LoadingOverlay height={"136rem"} styles={loadingStyles} topAlign />;
};

export default EpisodeViewContainer;
