import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useRef } from "react";
import Helmet from "react-helmet-async";

import Title from "components/Entities/Page/TitleAsync";

import BrandSafety from "../../EpisodeView/Insights/Sections/BrandSafety/BrandSafetyAsync";
import PodcastFAQForPro from "../PodcastFAQSection/PodcastFAQForPro";
import { CONTACTS_ID } from "./components/InsightNavigation";
import InsightsBar from "./components/InsightsBar";
import InsightsLoading from "./InsightsLoading";
import { insightStyles, primaryTitleStyles } from "./InsightsStyles";
import InsightContent from "./Sections/InsightContent/InsightContent";
import InsightsAudienceContainer from "./Sections/InsightsAudienceContainer";
import InsightChartsContainerAsync from "./Sections/InsightsCharts/InsightChartsContainerAsync";
import InsightsContacts from "./Sections/InsightsContacts/InsightsContacts";
import InsightsDetails from "./Sections/InsightsDetails";
import InsightsNetwork from "./Sections/InsightsNetwork";
import InsightsSocialContainer from "./Sections/insightSocials/InsightSocialContainerAsync";
import InsightsProCTA from "./Sections/InsightsProCTA";
import InsightsRateCard from "./Sections/InsightsRateCardAsync";
import InsightsSimilar from "./Sections/InsightsSimilar";
import PodcastInsightsSponsors from "./Sections/InsightsSponsors/PodcastInsightsSponsorsAsync";
import PublicPodcastInsightsSponsors from "./Sections/InsightsSponsors/PublicPodcastInsightsSponsorsAsync";
import InsightsStatus from "./Sections/InsightsStatus";
import VerifiedBanner from "./Sections/VerifiedBanner";

import { getBaseUrl } from "constants/base";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import {
  VIEW_SPONSOR_HISTORY_PERMISSION,
  VIEW_RATE_CARD_PERMISSION,
  userHasProPermission,
} from "utils/entity/user";
import { generateMetaArray } from "utils/meta";
import getVerifiedData from "utils/verifiedBanner/getVerifiedData";

import useInsightsRanksData from "hooks/useInsightsRanksData";
import useLoggedInUser from "hooks/useLoggedInUser";
import useProDataForPodcast from "hooks/useProDataForPodcast";
import { useStyles } from "hooks/useStyles";
import useTesting from "hooks/useTesting";
import useUserHasPermission from "hooks/useUserHasPermission";
import useUserHasPoliticalSkewPermission from "hooks/useUserHasPoliticalSkewPermission";
import useUserHasViewBrandSafetyPermission from "hooks/useUserHasViewBrandSafetyPermission";

import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...insightStyles,
  insights: {
    width: "100%",

    minHeight: "365rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "280rem",
    },
  },
  formLabel: {
    ...gStyles.avalonBold,
    fontSize: "1rem",
  },
  reportButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    marginRight: "1rem",

    [ScreenSizes.xlAndAbove]: {
      marginRight: 0,
    },
  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "2rem",

    [createBreakpoint({ min: 1170 })]: {
      gridTemplateColumns: "9.688rem 1fr",
    },
  },
  insightsTitleContainer: {
    display: "flex",
    alignItems: "Center",
    justifyContent: "space-between",
    marginBotton: "1.5rem",

    [ScreenSizes.lgAndAbove]: {
      marginBotton: 0,
    },
  },
};

const safetyStyles = {
  mainContainer: {
    marginTop: "2rem",
  },
};

const Insights = (props) => {
  const { podcast } = props;

  const { styles } = useStyles(baseStyles, props);

  const { isVerified, verifiedBy } = getVerifiedData(podcast);

  const hasBrandSafetyPermission = useUserHasViewBrandSafetyPermission();
  const hasPoliticalskewPermisison = useUserHasPoliticalSkewPermission();
  const userHasViewRateCardPermissions = useUserHasPermission(
    VIEW_RATE_CARD_PERMISSION
  );

  const audienceRef = useRef();
  const brandSafetyRef = useRef();
  const contentRef = useRef();
  const socialRef = useRef();
  const chartsRef = useRef();
  const detailsRef = useRef();
  const similarRef = useRef();
  const rateCardRef = useRef();
  const sponsorRef = useRef();
  const contactRef = useRef();

  const faqRef = useRef();

  const refs = useMemo(
    () =>
      [
        {
          ref: audienceRef,
          id: "Reach & Audience",
          label: "Reach & Audience",
        },
        hasPoliticalskewPermisison && {
          ref: contentRef,
          id: "Content",
          label: "Content",
        },
        hasBrandSafetyPermission && {
          ref: brandSafetyRef,
          id: "Brand Safety",
          label: "Brand Safety",
        },
        {
          ref: socialRef,
          id: "Social",
          label: "Social",
        },
        {
          ref: contactRef,
          id: CONTACTS_ID,
          label: "Contacts",
        },
        {
          ref: chartsRef,
          id: "Charts",
          label: "Charts",
        },
        userHasViewRateCardPermissions && {
          ref: rateCardRef,
          id: "Rate Card",
          label: "Rate Card",
        },
        {
          ref: sponsorRef,
          id: "Sponsors",
          label: "Sponsors",
        },
        {
          ref: detailsRef,
          id: "Details",
          label: "Details",
        },
        {
          ref: similarRef,
          id: "Similar",
          label: "Similar",
        },
      ].filter((elem) => !!elem),
    [
      hasBrandSafetyPermission,
      hasPoliticalskewPermisison,
      userHasViewRateCardPermissions,
    ]
  );

  const { entity: proData, loading } = useProDataForPodcast(podcast.get("id"));

  const { title, headerLink, meta } = useMemo(() => {
    if (!podcast) {
      return {};
    }
    const podcastUrl = `${getBaseUrl()}${getPodcastUrl(podcast)}`;
    const canonicalUrl = `${podcastUrl}/insights`;

    return {
      title: `Insights for ${podcast.get("title")}`,
      headerLink: [{ rel: "canonical", href: canonicalUrl }],
      meta: generateMetaArray({
        title: `Insights for ${podcast.get("title")}`,
        url: canonicalUrl,
        image: { url: podcast.get("image_url") },
        description: `Insights, contacts, audience details, sponsor history and much more for ${podcast.get(
          "title"
        )} and every other podcast.`,
      }),
    };
  }, [podcast]);

  const user = useLoggedInUser();
  const userIsPro = userHasProPermission(user);
  const userHasViewSponsorPermissions = useUserHasPermission(
    VIEW_SPONSOR_HISTORY_PERMISSION
  );

  const podcastId = podcast && podcast.get("id");
  const { ranksData } = useInsightsRanksData(podcastId);
  const hasSpotifyData = ranksData?.get("spotify")?.get("countries")?.size > 0;

  const testingAd = useTesting("ad");

  if (loading) {
    return <InsightsLoading />;
  }

  if (!podcast || !proData) {
    return null;
  }
  const hasClaimedPodcasts = user.get("has_claimed_podcasts");

  return (
    <div className={css(styles.insights)} data-id="insights-section">
      <Helmet title={title} meta={meta} link={headerLink} />
      <div className={css(styles.insightsTitleContainer)}>
        <Title
          title="Podcast Insights"
          noTopPadding
          TitleComponent="h1"
          styles={primaryTitleStyles}
          primary
          key="title"
        />
      </div>
      <div className={css(styles.mainContainer)}>
        <InsightsBar
          refs={refs}
          podcastId={podcast.get("id")}
          contactRef={contactRef}
          lastScrollableElementRef={faqRef}
        />
        <div>
          {userIsPro && isVerified && <VerifiedBanner partner={verifiedBy} />}
          <InsightsStatus podcast={podcast} key="status" />
          <InsightsAudienceContainer
            podcast={podcast}
            proData={proData}
            key="audience"
            audienceRef={audienceRef}
          />
          {hasPoliticalskewPermisison && (
            <InsightContent entity={podcast} passedRef={contentRef} />
          )}
          {hasBrandSafetyPermission && (
            <BrandSafety
              styles={safetyStyles}
              proData={proData}
              passedRef={brandSafetyRef}
            />
          )}
          <InsightsSocialContainer
            passedRef={socialRef}
            podcast={podcast}
            proData={proData}
            key="social"
          />
          <InsightsContacts
            podcast={podcast}
            proData={proData}
            key="contacts"
            passedRef={contactRef}
            showHeaderTools={userIsPro}
            showLoadMore={true}
            showVotes={userIsPro}
          />
          <InsightChartsContainerAsync
            passedRef={chartsRef}
            podcastId={podcastId}
          />
          {userHasViewRateCardPermissions && (
            <InsightsRateCard
              podcastTitle={podcast.get("title")}
              proData={proData}
              key="episode"
              passedRef={rateCardRef}
            />
          )}
          {userHasViewSponsorPermissions ? (
            <PodcastInsightsSponsors
              passedRef={sponsorRef}
              podcast={podcast}
              proData={proData}
              key="sponsors"
            />
          ) : (
            <PublicPodcastInsightsSponsors
              passedRef={sponsorRef}
              podcast={podcast}
              key="sponsors"
            />
          )}
          <InsightsDetails
            podcast={podcast}
            key="details"
            passedRef={detailsRef}
          />
          {proData.get("network") && (
            <InsightsNetwork
              podcast={podcast}
              proData={proData}
              key="network"
            />
          )}
          <InsightsSimilar
            podcast={podcast}
            key="similar"
            passedRef={similarRef}
          />
          {((hasClaimedPodcasts && !userIsPro) || testingAd) && (
            <InsightsProCTA podcast={podcast} key="procta" />
          )}
          <div ref={faqRef}>
            <PodcastFAQForPro
              podcastId={podcast.get("id")}
              hasSpotifyData={hasSpotifyData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Insights.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};

Insights.defaultProps = {
  podcast: null,
};

export default Insights;
