import PropTypes from "prop-types";
import { memo } from "react";

import InfoMessage from "components/Common/Messages/InfoMessage";

import ScreenSizes from "styles/ScreenSizes";

const INFO_STYLES = {
  message: {
    [ScreenSizes.mdAndBelow]: {
      display: "block",
    },
  },
};

const NoStubItemsMessage = (props) => {
  const { message } = props;

  return <InfoMessage styles={INFO_STYLES}>{message}</InfoMessage>;
};

NoStubItemsMessage.propTypes = {
  message: PropTypes.node.isRequired,
};

export default memo(NoStubItemsMessage);
