import { useMemo } from "react";

import { makeSelectCategoriesForPodcast } from "selectors/podcast";

import useReduxState from "hooks/useReduxState";

function usePodcastCategories(podcastId) {
  const selectCategoriesForPodcast = useMemo(
    makeSelectCategoriesForPodcast,
    []
  );

  const categories = useReduxState(
    (state) => selectCategoriesForPodcast(state, podcastId),
    []
  );

  return categories;
}
export default usePodcastCategories;
