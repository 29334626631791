import { css } from "aphrodite";
import { fromJS } from "immutable";
import upperFirst from "lodash/upperFirst";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import EntityLink from "components/Entities/EntityLink";

import getPeriodFromDays from "utils/date/getPeriodFromDays";
import { podcastExclusiveTo } from "utils/entity/exclusiveTo";
import getCategoryText from "utils/entity/getCategoryText";
import getCategoryUrl from "utils/entity/getCategoryUrl";
import sendGAEvent from "utils/sendGAEvent";
import commaList from "utils/text/commaList";
import indefiniteArticle from "utils/text/indefiniteArticle";
import { truncateString } from "utils/truncate";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  subtitleLink: {
    ...gStyles.fontSemiBold,

    ...getHoverQuery({
      textDecoration: "underline",
    }),
  },
  summaryContainer: {
    [ScreenSizes.lgAndAbove]: {
      minHeight: "1.188rem",
    },
  },
  flex: {
    display: "flex",
    gap: "0.5rem",
  },
};

function calcSummaryLength(categories, creators) {
  return (
    categories?.reduce((sum, cat) => sum + getCategoryText(cat).length, 0) +
    creators?.reduce((sum, creator) => sum + creator.get("name").length, 0) +
    "An podcast featuring & &".length
  );
}

function PodcastSummary(props) {
  const { podcast, maxLength } = props;
  const { styles } = useStyles(baseStyles, props);

  if (!podcast.has("summary") && !podcast.has("categories")) {
    return truncateString(podcast.get("description"), maxLength);
  }
  let categories = (
    podcast.getIn(["summary", "categories"]) ||
    fromJS(podcast.get("categories") || [])
  ).map((c) =>
    typeof c === "string" ? fromJS({ slug: c, text: getCategoryText(c) }) : c
  );
  let creators = podcast
    .getIn(["summary", "creators"], [])
    .filter((c) => c.has("name"));
  let length = calcSummaryLength(categories, creators);
  while (length > maxLength && (categories.size > 1 || creators.size > 0)) {
    if (categories.size >= creators.size && categories.size > 1) {
      categories = categories.pop();
    } else {
      creators = creators.pop();
    }
    length = calcSummaryLength(categories, creators);
  }
  const spotifyExclusive = podcastExclusiveTo(podcast, "spotify");

  const period =
    podcast.get("days_between_episodes") &&
    getPeriodFromDays(podcast.get("days_between_episodes"), {
      allowPer: false,
    }).toLowerCase();

  const typeString = `${period ? `${period} ` : ""}${
    spotifyExclusive ? "Spotify exclusive " : ""
  }`;

  const hasCategories = categories && categories?.size > 0;
  const hasCreators = creators && creators?.size > 0;

  return (
    <div className={css(styles.summaryContainer)}>
      {typeString || hasCategories
        ? upperFirst(
            indefiniteArticle(typeString || getCategoryText(categories.first()))
          )
        : "A"}{" "}
      {typeString}
      {hasCategories
        ? commaList(categories?.toArray(), (cat, index) => (
            <Link
              key={`${index}=${getCategoryText(cat)}`}
              className={css(styles.subtitleLink)}
              to={getCategoryUrl(cat)}
              onClick={() => {
                sendGAEvent({
                  action: "headerCategoryClick",
                  podcastId: podcast.get("id"),
                  podcastTitle: podcast.get("title"),
                  context: "PodcastPage",
                  categorySlug: cat.get("slug"),
                  categoryUrl: getCategoryUrl(cat),
                });
              }}
            >
              {getCategoryText(cat)}
            </Link>
          ))
        : ""}
      {" podcast"}
      {hasCreators && (
        <span className={css(styles.flex)}>
          {" featuring "}
          {commaList(creators.toArray(), (creator) => (
            <EntityLink
              entity_type="creator"
              entity_id={creator.get("id")}
              key={creator.get("id")}
            >
              {creator.get("name")}
            </EntityLink>
          ))}
        </span>
      )}
    </div>
  );
}

PodcastSummary.propTypes = {
  podcast: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
};

export default PodcastSummary;
