import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import InfoMessage from "components/Common/Messages/InfoMessage";

import { useStyles } from "hooks/useStyles";
import useTesting from "hooks/useTesting";

const baseStyles = {
  processingContainer: {
    marginBottom: "1.5rem",
  },
};

// might as well use the same list as the main page
// for now as we can't filter/sort/limit it yet anyway
const AboutProcessing = (props) => {
  const { podcast } = props;
  const { styles } = useStyles(baseStyles, props);

  const testing = useTesting("processing");

  if (podcast.get("processing_status") !== "initialParse" && !testing) {
    // TODO: Remove testing?
    return null;
  }

  return (
    <div className={css(styles.processingContainer)}>
      <InfoMessage background="#fff3d0">
        We've only just discovered this podcast and it's still being processed.
        You'll see a bit of info for now, but come back later to see more!
      </InfoMessage>
    </div>
  );
};

AboutProcessing.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};

export default AboutProcessing;
